import React from 'react';
import { FieldSelect } from '../../../../components';
import { required, composeValidators } from '../../../../util/validators';
import css from './CustomDurationField.module.css';

//array of 24 strings representing Duration. (e.g. 1 month.. 24 months)
export const DURATIONARRAY = [...Array(25)].map((_, i) => {
  return i === 0
    ? { key: `${i + 1}_month`, label: `${i + 1} month` }
    : i === 24
    ? { key: `${i + 1}_months`, label: `More than 24 months` }
    : { key: `${i + 1}_months`, label: `${i + 1} months` };
});

const CustomDurationField = props => {
  const { intl, listingType } = props;

  const defaultLabelId = `EditListingDetailsForm.durationLabel`;

  let labelId = defaultLabelId;

  const specificMessageId = `${defaultLabelId}_${listingType}`;

  // Check if the specific IDs exist in the internationalization system
  if (intl.messages[specificMessageId]) {
    labelId = specificMessageId;
  }

  const durationLabel = intl.formatMessage({ id: labelId });
  const durationMinMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.durationMin',
  });
  const durationMaxMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.durationMax',
  });
  const durationMinPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.durationMinPlaceholder',
  });
  const durationMaxPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.durationMaxPlaceholder',
  });
  const durationRequiredMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.durationRequired',
  });

  return (
    <div>
      <label>
        <span>{durationLabel}</span>
      </label>
      <div className={css.durationJob}>
        <FieldSelect
          className={css.duration}
          name="durationMin"
          id="durationMin"
          label={durationMinMessage}
          validate={composeValidators(required(durationRequiredMessage))}
        >
          <option disabled value="">
            {durationMinPlaceholderMessage}
          </option>
          {DURATIONARRAY.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
        <FieldSelect
          className={css.duration}
          name="durationMax"
          id="durationMax"
          label={durationMaxMessage}
          validate={composeValidators(required(durationRequiredMessage))}
        >
          <option disabled value="">
            {durationMaxPlaceholderMessage}
          </option>
          {DURATIONARRAY.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
      </div>
    </div>
  );
};

export default CustomDurationField;
