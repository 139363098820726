import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { priceData } from './ListingPage.shared';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const SectionPackagesMaybe = props => {
  const { publicData, intl } = props;

  if (!publicData || !publicData.packages) {
    return null;
  }
  const packages = publicData.packages;

  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        Packages
      </Heading>
      <ul className={css.packages}>
        {packages.map((pkg, index) => {
          const priceToMoney = new Money(pkg.price.amount, pkg.price.currency);
          const { formattedPrice } = priceData(priceToMoney, pkg.price.currency, intl);
          return (
            <li key={index} className={css.packageListItem}>
              <div className={css.packageItemData}>
                <div className={css.packageItemHeading}>
                  <div className={css.packageName}>{pkg.packageName}</div>
                  <div className={css.packagePrice}>{formattedPrice}</div>
                </div>
                <div className={css.packageDescription}>{pkg.packageDescription}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SectionPackagesMaybe;
