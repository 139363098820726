import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import css from './ListingPage.module.css';
import moment from 'moment';
import { DURATIONARRAY } from '../EditListingPage/EditListingWizard/EditListingDetailsPanel/CustomDurationField';
import { TEXT_TABS } from '../EditListingPage/EditListingWizard/EditListingWizard';
import { TIMEFRAMEARRAY } from '../EditListingPage/EditListingWizard/EditListingDetailsPanel/CustomTimeFrameAvailableField';
import classNames from 'classnames';

//extra fields
const extraFieldsConfig = [{ key: 'location' }];

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingFieldConfigs, isFieldForCategory, intl } = props;

  const isHotel = publicData?.listingType === 'oogo_hotel';

  if (!publicData || !listingFieldConfigs || isHotel) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label: labelRaw } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;
    const label = labelRaw && labelRaw.split('--')[0].trim();

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);
      const findSelectedOptionMulti = multiEnumValue =>
        enumOptions.filter(option => multiEnumValue.includes(option.option));

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label.split('--')[0].trim(), label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : schemaType === 'text'
        ? filteredConfigs.concat({ key, value, label })
        : [
            'jobLocation',
            'jobLocation_tutor',
            'jobLocation_sleep_consultant',
            'jobType',
            'livingChoice',
          ].includes(key)
        ? filteredConfigs.concat({ key, value: findSelectedOptionMulti(value), label })
        : filteredConfigs;
    } else if (key === 'startEndDateJob' && typeof publicData['startDateJob'] !== 'undefined') {
      return filteredConfigs.concat({
        key,
        value: [publicData['startDateJob'], publicData['endDateJob']],
        label: intl.formatMessage({ id: 'EditListingDetailsForm.startEndDateJob.label' }),
      });
    } else if (key === 'durationMinMax' && typeof publicData['durationMin'] !== 'undefined') {
      return filteredConfigs.concat({
        key,
        value: [publicData['durationMin'], publicData['durationMax']],
        label: 'Duration:',
      });
    } else if (key === 'timeFrameWeeks' && typeof publicData['timeFrame'] !== 'undefined') {
      return filteredConfigs.concat({
        key,
        value: publicData['timeFrame'],
        label: 'Time Frame:',
      });
    } else if (key === 'location' && typeof publicData['location'] !== 'undefined') {
      return filteredConfigs.concat({
        key,
        value: publicData[key],
        label: 'Location:',
      });
    }
    return filteredConfigs;
  };

  const allFields = listingFieldConfigs.concat(extraFieldsConfig);
  const filteredFields = allFields.filter(
    field =>
      field.key !== 'onboardingType' &&
      !TEXT_TABS.includes(field.key) &&
      field.key !== 'hotelDescription'
  );
  const existingListingFields = filteredFields.reduce(pickListingFields, []);

  const getValuesStarEndDateJob = field => {
    if (!field.value) {
      return null;
    }
    const rawStartDateJob = field.value[0];
    const startDateJob = `${moment(rawStartDateJob).format('MMMM D, YYYY')}`;
    const rawEndDateJob = field.value[1] || [];
    const endDateJob = `${moment(rawEndDateJob).format('MMMM D, YYYY')}`;
    return endDateJob ? (
      <div>
        <span className={css.wordWeight}>{startDateJob}</span>
        {endDateJob ? (
          <>
            <span className={css.conector}> - </span>
            <span className={css.wordWeight}>{endDateJob}</span>
          </>
        ) : null}
      </div>
    ) : null;
  };

  const getValuesTimeFrame = field => {
    if (!field.value) {
      return null;
    }
    const selectedTimeFrameOption = TIMEFRAMEARRAY.find(o => o.key === field.value);

    const { label } = selectedTimeFrameOption;
    return selectedTimeFrameOption ? <span className={css.wordWeight}>{label}</span> : null;
  };

  const getValuesDuration = field => {
    if (!field.value) {
      return null;
    }
    const selectedDurationMinOption = DURATIONARRAY.find(o => o.key === field.value[0]);
    const selectedDurationMaxOption = DURATIONARRAY.find(o => o.key === field.value[1]);
    const { label: minLabel } = selectedDurationMinOption;
    const { label: maxLabel } = selectedDurationMaxOption;
    return selectedDurationMinOption && selectedDurationMaxOption ? (
      <div>
        <span className={css.wordWeight}>{minLabel}</span> <span className={css.conector}> - </span>
        <span className={css.wordWeight}>{maxLabel}</span>
      </div>
    ) : null;
  };
  const getValuesLocation = field => {
    if (!field.value) {
      return null;
    }
    const { address } = field.value;
    const addressArray = address.split(',').map(x => x.trim());
    const country = addressArray.pop();
    const town = addressArray.pop();
    return address ? (
      <div>
        {town && <span className={css.wordWeight}>{town}</span>}
        {town && ', '}
        {country && <span className={css.wordWeight}>{country}</span>}
      </div>
    ) : null;
  };

  const getValuesMulti = field => {
    if (!field.value || !Array.isArray(field.value)) {
      return null;
    }

    const labels = field.value.map(option => option.label);
    let formattedLabels;

    if (labels.length === 2) {
      formattedLabels = `${labels[0]} or ${labels[1]}`;
    } else if (labels.length > 2) {
      formattedLabels = labels.slice(0, -1).join(', ') + ', or ' + labels[labels.length - 1];
    } else {
      formattedLabels = labels[0];
    }

    return <span className={classNames(css.wordWeight, css.multiOptions)}>{formattedLabels}</span>;
  };

  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields.map(detail => {
          if (!detail.value) {
            return null;
          }
          return (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>{detail.label}</span>
              {detail.key === 'startEndDateJob' ? (
                getValuesStarEndDateJob(detail)
              ) : detail.key === 'durationMinMax' ? (
                getValuesDuration(detail)
              ) : detail.key === 'timeFrameWeeks' ? (
                getValuesTimeFrame(detail)
              ) : detail.key === 'location' ? (
                getValuesLocation(detail)
              ) : [
                  'jobLocation',
                  'jobLocation_tutor',
                  'jobLocation_sleep_consultant',
                  'jobType',
                  'livingChoice',
                ].includes(detail.key) ? (
                getValuesMulti(detail)
              ) : (
                <span>{detail.value}</span>
              )}
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
};

export default SectionDetailsMaybe;
